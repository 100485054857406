import { JWT_SECRET, REFRESH_TOKEN_COOKIES, TOKEN_COOKIES } from "@const/shared"
import { GoogleLoginResponse, LoginResponse, RawTokenData, RegisterResponse } from "@interfaces/auth"
import jwt from "jsonwebtoken"
import nookies, { destroyCookie, setCookie } from "nookies"

export function setTokenCookies(tokenResponse: LoginResponse | RegisterResponse | GoogleLoginResponse) {
  const token = tokenResponse.token
  let tokenData: RawTokenData | undefined

  if (token) {
    jwt.verify(token, JWT_SECRET, { algorithms: ["HS256"] }, async (err, decoded) => {
      if (err) {
        deleteTokenCookies()
        return ""
      } else {
        setCookie(null, TOKEN_COOKIES, tokenResponse.token, {
          maxAge: 3 * 60 * 60,
          path: "/",
          domain: domainChecker(),
        })

        setCookie(null, REFRESH_TOKEN_COOKIES, tokenResponse.refreshToken, {
          maxAge: 3 * 60 * 60,
          path: "/",
          domain: domainChecker(),
        })
        tokenData = decoded as RawTokenData
      }
    })
  }

  return tokenData
}

export function decodedToken(token: string): RawTokenData | null {
  let tokenData = null
  if (token) {
    jwt.verify(token, JWT_SECRET, { algorithms: ["HS256"] }, async (err, decoded) => {
      if (err) {
        return ""
      } else {
        tokenData = decoded as RawTokenData
      }
    })
  }

  return tokenData
}

export function deleteTokenCookies() {
  const isProduction = process.env.NODE_ENV === "production"

  destroyCookie(null, TOKEN_COOKIES, {
    domain: isProduction
      ? process.env.COOKIES_DOMAIN || process.env.HARISENIN_COOKIES_DOMAIN || ""
      : "",
    path: "/",
  })

  destroyCookie(null, "HS_TEMP")

  destroyCookie(null, REFRESH_TOKEN_COOKIES, {
    domain: isProduction
      ? process.env.COOKIES_DOMAIN || process.env.HARISENIN_COOKIES_DOMAIN || ""
      : "",
    path: "/",
  })
}

export function getTokenInfo(): LoginResponse {
  const cookies = nookies.get({})

  const token = cookies[TOKEN_COOKIES]
  const refreshToken = cookies[REFRESH_TOKEN_COOKIES]

  return { token, refreshToken }
}

export function domainChecker(): string {
  const isProduction = process.env.NODE_ENV === "production"

  if (isProduction) {
    return process.env.COOKIES_DOMAIN || process.env.HARISENIN_COOKIES_DOMAIN || ""
  } else {
    return ""
  }
}
